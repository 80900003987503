.logo {
  height: 8rem;
  animation: animate 1.2s ease-in-out infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
